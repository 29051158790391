import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import { Button, Menu, MenuItem, Tooltip } from '@mui/material'; // Import MUI components
import {
  getHistoricalStream,
  getLiveStream,
  getStreamTimes,
} from '../../utils/video';
import type { Location } from '../../types/Location';
import type { Franchise } from '../../types/Franchise';
import { useCamera } from '../../hooks/useCamera';
import VideoPlayer from './VideoPlayer';
import { callMothership, getMothershipMetrics, getMothershipLogs } from '../../utils/api';




export default function VideoPlayerKinesis({
  location,
  camera,
  metrics = {},  // Set an empty object as a default if metrics is undefined or null
}: {
  location: Location;
  camera: string;
  metrics?: object | null;  // Allow null as a valid value for metrics
}): JSX.Element {
  const {
    kinesisVideo,
    isLive,
    playbackSpeed,
    videoDateTime,
    setVideoDateTime,
    isPlaying,
    setIsPlaying,
  } = useCamera();
  const videoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<videojs.Player | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For MUI Menu
  const open = Boolean(anchorEl); // Check if the menu is open

  console.log('metricsssss', metrics);

  // Ensure systemStatus has a fallback in case metrics is null
  let systemStatus = metrics || {};  // If metrics is null or undefined, use an empty object as fallback

  console.log('aaaaaaaaaaaaaaaaa', systemStatus);

  // Menu handling for dropdown
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hook to get the current location
  const locationPath = useLocation();

  // Initialize videojs on component mount
  useEffect(() => {
    if (videoRef.current === null) {
      return;
    }
    const playerObject = videojs(videoRef.current, {
      autoplay: true,
      muted: true,
      playsinline: true,
      controls: true,
      preload: 'auto',
      userActions: {
        click: false,
      },
      html5: {
        vhs: {
          overrideNative: true,
        },
      },
      disablePictureInPicture: true,
      nativeControlsForTouch: false,
    });
    setPlayer(playerObject);

    playerObject.on('error', () => {
      playerObject.load();
      playerObject.play()?.catch((err) => {
        console.log(err);
      });
    });

    playerObject.on('loadedmetadata', () => {
      const w = wrapperRef.current?.clientWidth;
      playerObject.width(w);
    });

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }

    setIsPlaying(true);

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [videoRef.current]);

  // Play or pause the video based on isPlaying state
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (!isPlaying) {
      player.pause();
    } else {
      player.play()?.catch((err: any) => {
        console.log(err);
      });
    }
  }, [isPlaying]);

  // Change video speed
  useEffect(() => {
    if (player === null) {
      return;
    }
    try {
      player.playbackRate(playbackSpeed);
      player.defaultPlaybackRate(playbackSpeed);
    } catch (err) {
      console.log(err);
    }
  }, [playbackSpeed]);

  // Switch between live and historical video
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }
  }, [player, isLive, videoDateTime, location, camera]);

  // Get stream name based on the camera type
  function getStreamName(): string {
    let streamName = '';
    if (camera === 'CV') {
      streamName = `CV_${location.id}`;
    } else if (camera === 'CV_DT') {
      streamName = `CV_DT_${location.id}`;
    } else if (camera === 'MONITOR') {
      streamName = `MONITOR_${location.id}`;
    } else {
      streamName = `${location.id}_${camera}`;
    }
    return streamName;
  }

  // Jump to the live stream
  async function goToLive(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      const streamUrl = await getLiveStream(getStreamName(), kinesisVideo);

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
      player.playbackRate(1);
      player.defaultPlaybackRate(1);
    } catch (err) {
      console.log('Error going to live stream.');
      console.warn(err);
    }
  }

  // Jump to the historical stream
  async function goToHistorical(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      player.on('ended', () => {
        console.log(videoDateTime);
        setVideoDateTime(new Date(videoDateTime.valueOf() + 10 * 60 * 1000));
        console.log(videoDateTime);
      });
      const { startTime, endTime } = getStreamTimes(
        videoDateTime,
        location.timezone
      );

      const streamUrl = await getHistoricalStream(
        getStreamName(),
        kinesisVideo,
        startTime,
        endTime
      );

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
    } catch (err) {
      console.log('Error going to historical stream.');
      console.warn(err);
    }
  }

  // Render a circle based on boolean state
  const renderCircle = (value: boolean) => {
    return (
      <span
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: value ? 'green' : 'red',
          display: 'inline-block',
          marginLeft: 5,
        }}
      ></span>
    );
  };

  // Render circle with color based on the value of ram_usage
  const renderRamUsageCircle = (ramUsage: number) => {
    let color = 'green'; // Default to green
    if (ramUsage > 70 && ramUsage < 90) {
      color = 'yellow'; // Yellow for RAM usage > 70 and < 90
    } else if (ramUsage >= 90) {
      color = 'red'; // Red for RAM usage >= 90
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  // Render circle with color based on the value of disk_usage
  const renderDiskUsageCircle = (diskUsage: number) => {
    let color = 'green'; // Default to green
    if (diskUsage > 45 && diskUsage < 70) {
      color = 'orange'; // Orange for disk usage > 45 and < 70
    } else if (diskUsage >= 70) {
      color = 'red'; // Red for disk usage >= 70
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  // Render circle with color based on the value of upload speed
  const renderUploadSpeedCircle = (uploadSpeed: number) => {
    let color = 'red'; // Default to red for upload speed < 10
    if (uploadSpeed >= 10 && uploadSpeed <= 20) {
      color = 'yellow'; // Yellow for upload speed between 10 and 20
    } else if (uploadSpeed > 20) {
      color = 'green'; // Green for upload speed > 20
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  return (
    <div>
      <VideoPlayer
        location={location}
        cameraId={camera}
        videoRef={videoRef}
        wrapperRef={wrapperRef}
      />

      {camera === 'MONITOR' && locationPath.pathname === '/mothership' && ( // Only show if on /mothership
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick} // Open dropdown on click
          >
            Actions
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose} // Close dropdown
          >
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'refresh_monitors');
                handleClose(); // Close after selecting
              }}
            >
              Refresh
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'docker_down_up');
                handleClose();
              }}
            >
              Docker Comp
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'flip_monitors');
                handleClose();
              }}
            >
              Flip Monitors
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'remove_update_manager');
                handleClose();
              }}
            >
              Remove Update Manager
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'set_monitor_and_streams');
                handleClose();
              }}
            >
              Set Monitor and Streams
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'run_to_be_cronned');
                handleClose();
              }}
            >
              Run TBC
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'run_update_d_c_file_NEW');
                handleClose();
              }}
            >
              Run update DC new DO NOT DO THIS!
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'retake_images');
                handleClose();
              }}
            >
              Retake Images
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'pull_health');
                handleClose();
              }}
            >
              Pull Health
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'docker_pull');
                handleClose();
              }}
            >
              Docker Pull
            </MenuItem>
            <MenuItem
              onClick={() => {
                callMothership(location.id, 'set_HM_background');
                handleClose();
              }}
            >
              HM background
            </MenuItem>

          </Menu>

          {/* Render the system status information */}
          {Object.keys(systemStatus).length > 0 && (
            <div style={{ marginTop: 10 }}>
              <div>
                {renderCircle(systemStatus.internet_connection)} <span>Internet Connection: {systemStatus.internet_connection ? 'Connected' : 'Disconnected'}</span>
              </div>
              <div>
                {renderRamUsageCircle(systemStatus.ram_usage)} <span>RAM Usage: {systemStatus.ram_usage}%</span>
              </div>
              <div>
                {renderDiskUsageCircle(systemStatus.disk_usage)} <span>Disk Usage: {systemStatus.disk_usage}%</span>
              </div>
              <div>
                {renderUploadSpeedCircle(systemStatus.speedtest_upload)} <span>Upload Speed: {systemStatus.speedtest_upload} Mbps</span>
              </div>
              <div>
                <span>Last Boot Time: {systemStatus.last_boot_time}</span>
              </div>
              <div>
                <span>Internal IP: {systemStatus.internal_ip}</span>
              </div>
              <div>
                <span>External IP: {systemStatus.external_ip}</span>
              </div>
              <div>
                <span>OS Type: {systemStatus.os_type}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
