/**
 * Container component for the ranks table.
 */

import { useEffect, useState } from 'react';
import { sortDataByColumn } from '../../utils/tables';
import type { TableColumn } from '../../types/TableColumn';
import type { Rank } from '../../types/Rank';
import { getRanks, getRegionsStats } from '../../utils/api';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import RefreshButton from '../buttons/RefreshButton';
import { getMissingLocationIds } from '../../utils/locations';
import DropdownRegionMultiple from '../dropdowns/DropDownRegionMuli';
import type { RegionsStats } from '../../types/RegionsStats';

const tableTitle = 'Location Ranks';

const columns = [
  { label: 'Rank', key: 'rank', type: 'number' },
  {
    label: 'Location',
    key: 'locationName',
    type: 'link',
    href: '/cameras/?lid=locationId',
  },
  { label: 'Average', key: 'averageServiceTime', type: 'seconds' },
  { label: 'Customer Count', key: 'customerCount', type: 'number' },
  {
    label: 'Difference From Best Day',
    key: 'differenceFromBestDay',
    type: 'secondsDifference',
  },
  {
    label: 'WOW Change',
    key: 'weekOverWeekChangeInTime',
    type: 'secondsDifference',
  },
  { label: 'Rank Change', key: 'rankChange', type: 'numberDifference' },
];

export default function RanksTable(): JSX.Element {
  const {
    selectedFranchise,
    selectedDates,
    selectedLocations,
    toggled,
    userLocations,
  } = useData();

  const [data, setData] = useState<Rank[]>([]);
  const [displayData, setDisplayData] = useState<Rank[]>([]);
  const [sortColumn, setSortColumn] = useState(columns[0]);
  const [sortDirection, setSortDirection] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);

  function getDistinctRegions(rawData: RegionsStats[]): any[] {
    if (rawData.length === 0) return [];
    const regions: Record<string, string> = {};
    const cols = [];
    // Get all possible regions.
    for (const data of rawData) {
      for (const region of data.regions) {
        regions[region.key] = region.displayName;
      }
    }

    for (const key in regions) {
      cols.push({
        id: key,
        value: regions[key],
      });
    }

    // Sort the columns by label.
    cols.sort((a, b) => a.id.localeCompare(b.id));

    return cols;
  }

  function handleSelectedRegionsChange(id: string): void {
    let newSelectedRegions;

    const regionInSelected = selectedRegions.find((region) => region.id === id);

    if (regionInSelected !== undefined) {
      newSelectedRegions = selectedRegions.filter((region) => region.id !== id);
      setSelectedRegions(newSelectedRegions);
    } else {
      const region = regions.find((region) => region.id === id);
      newSelectedRegions = [...selectedRegions, region];
      setSelectedRegions([...selectedRegions, region]);
    }

    const regionKeys = newSelectedRegions.map((r) => r.id);
    const newData = data;

    newData.forEach((location, index) => {
      const values: number[] = [];

      location.regionsAgg.regions.forEach((r) => {
        if (regionKeys.includes(r.key)) {
          values.push(r.timeSpent);
        } else {
          values.push(0);
        }
      });
      newData[index].averageServiceTime = values.reduce((a, b) => a + b);
    });

    const col = columns.find((c) => c.key === 'averageServiceTime');
    if (col !== undefined) {
      backGroundSort(col, newData);
    }
  }

  async function fetchData(): Promise<void> {
    if (selectedFranchise === null) {
      return;
    }
    setIsLoading(true);

    const oneWeekAgo = new Date(selectedDates[0]);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const regionEndDate = new Date(selectedDates[0]);
    regionEndDate.setDate(regionEndDate.getDate() + 1);

    const regionData = await getRegionsStats(
      selectedFranchise.id,
      selectedDates[0],
      regionEndDate,
      userLocations
        .filter((location) => selectedLocations.includes(location))
        .map((location) => location.id),
      toggled === 'drive-thru'
    );

    const rankData = await getRanks(
      selectedFranchise.id,
      oneWeekAgo,
      selectedDates[0],
      userLocations
        .filter((location) => selectedLocations.includes(location))
        .map((location) => location.id),
      toggled === 'drive-thru'
    );
    const modifiedData = addBlankLocations(rankData);

    modifiedData.forEach((location, index) => {
      const matchingRegionEntry = regionData.find(
        (regLocation) => regLocation.locationId === location.locationId
      );

      if (matchingRegionEntry !== undefined) {
        const values: number[] = [];

        matchingRegionEntry.regions.forEach((r) => {
          if (r.timeSpent === undefined) {
            r.timeSpent = 0;
          }
          values.push(r.timeSpent);
        });

        if (values.length > 0) {
          modifiedData[index].averageServiceTime = values.reduce(
            (a, b) => a + b
          ); // OVERRIDING DETECTION AVERAGE
        } else {
          modifiedData[index].averageServiceTime = 0;
        }
        modifiedData[index].regionsAgg = matchingRegionEntry;
      }
    });

    setSelectedRegions(
      getDistinctRegions(modifiedData.map((d) => d.regionsAgg))
    );
    setRegions(getDistinctRegions(modifiedData.map((d) => d.regionsAgg)));

    const col = columns.find((c) => c.key === 'averageServiceTime');
    if (col !== undefined) {
      backGroundSort(col, modifiedData);
    }
    setIsLoading(false);
  }

  // Fetch the data on load.
  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, []);

  // Fetch the data when the dates, franchise, or toggle change.
  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err);
    });
  }, [selectedDates[0], selectedFranchise, toggled]);

  // Set the data to display based on the selected locations.
  useEffect(() => {
    const selected = selectedLocations.map((location) => location.displayName);
    setDisplayData(data.filter((row) => selected.includes(row.locationName)));
  }, [data, selectedLocations]);

  // Sort a column.
  function handleSort(column: TableColumn): void {
    const newData = sortDataByColumn(data, column, sortDirection);
    setData(newData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Add blank locations to the display data.
  function addBlankLocations(data: Rank[]): Rank[] {
    const missingLocationIds = getMissingLocationIds(
      selectedLocations.map((location) => location.id),
      data.map((row) => row.locationId)
    );

    for (const id of missingLocationIds) {
      const location = selectedLocations.find((location) => location.id === id);
      const regions =
        toggled === 'lobby'
          ? (location?.lobbyConfiguration?.regions as any[])
          : (location?.dtConfiguration?.regions as any[]);
      if (location !== undefined && regions !== undefined) {
        for (const region of regions) {
          region.timeSpent = 0;
        }
        data.push({
          rank: 0,
          locationName: location.displayName,
          averageServiceTime: 0,
          customerCount: 0,
          differenceFromBestDay: 0,
          weekOverWeekChangeInTime: 0,
          rankChange: 0,
          locationId: location.id,
          regionsAgg: {
            locationId: location.id,
            locationName: location.displayName,
            regions,
          },
        });
      }
    }
    return data;
  }

  function backGroundSort(column: TableColumn, oldData: Rank[]): void {
    const newData = sortDataByColumn(oldData, column, 1);
    newData.forEach((row: Rank, index) => (newData[index].rank = index + 1));
    setData(newData);
    setSortColumn(column);
    setSortDirection(1);
  }

  if (selectedFranchise === null || isLoading) {
    return (
      <TableLoader
        columns={columns.map((column) => column.label)}
        title={tableTitle}
        handleRefresh={() => {
          fetchData().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <TableContainer dataCy="ranks-table">
      <TableHeader title={tableTitle}>
        <DropdownRegionMultiple
          regionStats={regions}
          selectedRegionStats={selectedRegions}
          handlSelectedRegionStatsChange={handleSelectedRegionsChange}
        />
        <RefreshButton
          handleRefresh={() => {
            fetchData().catch((err) => {
              console.error(err);
            });
          }}
          dataCy="refresh-button-ranks"
        />
      </TableHeader>
      <Table>
        <TableHead
          columns={columns}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
        <TableBody columns={columns} data={displayData} />
      </Table>
    </TableContainer>
  );
}
